
import './App.css';
import Entry from './App/App';
import Index from './App/Components/Index';


function App() {
  return (
 
<Entry/>
 
  );
}

export default App;
